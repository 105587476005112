export default function slick() {
  $('.p-voice_loop').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 10000,
    slidesToShow: 3,
    slidesToScroll: 5,
    swipe: false,
    centerMode: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive:[
      {
        breakpoint: 768,
        settings:{
          slidesToShow:1,
        }
      },
    ]
  })
}
