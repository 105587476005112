export default function googleMaps() {
  const headElement = document.querySelector('head');
  const scriptElement = document.createElement('script');

  window.initGoogleMaps = function() {
    const mapList = [
      {
        $element: $('#map1'),
        option: {
          center: { lat: 36.565364, lng: 136.674476 },
          zoom: 16,
          disableDefaultUI: true,
        },
        icon: {
          url: '/img/icon_map-marker_01.svg',
          anchor: new google.maps.Point(48, 100),
          scaledSize: new google.maps.Size(96, 100),
        },
      },
    ];

    $.each(mapList, function(index) {
      let map;

      if (mapList[index].$element.length === 0) {
        return true;
      }
      map = new google.maps.Map(
        mapList[index].$element[0],
        mapList[index].option,
      );
      new google.maps.Marker({
        position: { lat: 36.563564, lng: 136.674476 },
        map,
        icon: mapList[index].icon,
      });
    });
  };
  scriptElement.src =
    'https://maps.googleapis.com/maps/api/js?callback=initGoogleMaps&key=AIzaSyAP2fQai_uhCX32KpS2rJcZfC4i6zTou3k';
  headElement.appendChild(scriptElement);
}
