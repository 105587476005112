export default function scrollTarget($target, duration, callback = $.noop) {
  const $header = $('.l-header');
  let targetY;

  if ($target.length > 0) {
    targetY = Math.ceil($target.offset().top);
    if (window.innerWidth < 980) {
      targetY -= $header.height();
      if (targetY < 0) {
        targetY = 0;
      }
    }
    if (duration === 0) {
      $(window).scrollTop(targetY);
      callback();
    } else {
      $.when(
        $('html, body').animate({ scrollTop: targetY }, duration, 'swing'),
      ).done(() => {
        callback();
      });
    }
  }
}
